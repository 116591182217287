import React, { useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from 'axios';

export const ContactUs = () => {    
  const [subject,subjectFunc] = useState('');  
  const [sendername,sendernameFunc] = useState('');
  const [emailsState,emailsStateFunc] = useState('');
  const [templatesState,templatesStateFunc] = useState('aol-html');
  const [linkState,linkStateFunc] = useState('aol-html');
  const [isLoading,setIsLoading] = useState(false)
  const [mailTypeState,mailTypeStateFunc] = useState('html')
  const [plainTextState,plainTextStateFunc] = useState('')
  const [replyNameState,replyNameStateFunc] = useState('')
  const [replyEmailState,replyEmailStateFunc] = useState('')
  
  
  const sendEmail = (e) => {    
    e.preventDefault();
    setIsLoading(true);
    const emailclientPayload = {   
      subject:subject,
      email:emailsState,
      template:templatesState,
      link:linkState,
      sendername:sendername,
      plaintext: plainTextState,
      mailtype: mailTypeState,
      replyname: replyNameState,
      replyemail: replyEmailState
  }
  console.log(emailclientPayload)
  if(emailsState.split(',').length > 100){
    alert("You can only send 100 emails per time...")
    setIsLoading(false)
    return
  }
  axios
        .post(`https://email.swampoint.com/sendmail/`,emailclientPayload)
        .then(data =>{
            // let datas = data.data['message']
            // notify(datas)
            alert(JSON.stringify(data.data))
            setIsLoading(false);
        })
        .catch(e =>{
          setIsLoading(false)
            console.log("NICE BUT U HAVE AN ERRROR:",e)
        });
  };
 

  const emailsHandler = (event) =>{
    // console.log(event)
    emailsStateFunc(event.target.value)
  }

  const linkHandler = (event) =>{
    // console.log(event)
    linkStateFunc(event.target.value)
  }

  const subjectsHandler = (event) =>{
    // console.log(event)
    subjectFunc(event.target.value)
  }
  
  const senderHandler = (event) =>{
    // console.log(event)
    sendernameFunc(event.target.value)
  }
  
  const templatesHandler = (event) =>{
    // console.log(event)
    templatesStateFunc(event.target.value)
  }

  const mailtypeHandler = (event) =>{
    mailTypeStateFunc(event.target.value)
  }
  
  const plainTextHandler = (event) =>{
    plainTextStateFunc(event.target.value)
  }

  const replyNameHandler = (event) =>{
    replyNameStateFunc(event.target.value)
  }

  const replyEmailHandler = (event) =>{
    replyEmailStateFunc(event.target.value)
  }

  return (
    <div style={{
      padding: "10px"
    }}>
    <form onSubmit={sendEmail.bind(this)}>
     <p > <label>Subject</label></p>
     <p > <input style={{
      width:"100%"
     }} type="text" onChange={subjectsHandler.bind(this)} name="user_name" />
      </p>
      <p > <label>Identity Name</label></p>
     <p > <input style={{
      width:"100%"
     }} type="text" name="sender_name" onChange={senderHandler.bind(this)} />
      </p>
      <p>
      <label>Emails (separate with a comma)</label>
      </p>
      <p>
      <textarea
      style={{
        width:"100%",
        height: "75px"
       }} onChange={emailsHandler.bind(this)} name="emails" />      
      </p>
      <p>
        Emails Count:
        {emailsState.split(",").length}
      </p>
      <p>
      <label>Message Template</label>
      </p>
      <p>
        <select onChange={templatesHandler.bind(this)} style={{
      width:"100%"
     }}  name="templates">
    <option value={"aol-html"}>AOL HTML</option>
    <option value={"yahoo-html"}>Yahoo HTML</option>

        </select>
      </p>
      <p>
        <select onChange={mailtypeHandler.bind(this)} style={{
      width:"100%"
     }}  name="mailtype">
    <option value={"htm"}>HTML Message</option>
    <option value={"plain"}>Plain Message</option>

        </select>
      </p>
      <p>
      <label>Enter plain text</label>
      </p>
      <p>
      <textarea
      style={{
        width:"100%",
        height: "75px"
       }} onChange={plainTextHandler.bind(this)} name="plaintext" />      
      </p>
      <p> <input style={{
      width:"100%"
     }}  type="text" placeholder="reply Name" name="replyname"  onChange={replyNameHandler.bind(this)}/>
      </p>
      <p> <input style={{
      width:"100%"
     }}  type="text" placeholder="reply Email" name="replyemail"  onChange={replyEmailHandler.bind(this)}/>
      </p>

      <p> <input style={{
      width:"100%"
     }}  type="text" placeholder="Link to shot..." name="link"  onChange={linkHandler.bind(this)}/>
      </p>
      {!isLoading && (<input type="submit" value="Send" />)}
      {isLoading && (<input type="button" value="Send....." />)}
    </form>
    </div>
  );
};